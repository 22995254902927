import { Link } from 'gatsby'
import React from 'react'
import { useUserState } from '../context/UserContext';

const Footer = props => {
  const { user } = useUserState();

  return (
    <footer id="footer">
      <section>
        <h2>Amangwe Group</h2>
        <p>
          Coming together to work towards financial freedom for the African nation.
        </p>
      </section>
      <section>
        <h2>Contact Us</h2>
        <dl className="alt">
          <dt>Address</dt>
          <dd>1234 Somewhere Road &bull; Johannesburg &bull; RSA</dd>
          <dt>Phone</dt>
          <dd>+27 65 125 1260</dd>
          <dt>Email</dt>
          <dd>
            <a href="mailto:info@amangwe.com">info@amangwe.com</a>
          </dd>
          {user && (
            <>
              <dt>Mailbox</dt>
              <dd>
                <a href="https://webmail.konsoleh.co.za/login">Go to admin email</a>
              </dd>
            </>
          )}
          <dt>Portal</dt>
          <dd>
            <Link to="/sign-in">Sign into admin portal</Link>
          </dd>
        </dl>
        <ul className="icons">
          {/* <li>
          <a
            href="https://twitter.com/huntaroSan"
            className="icon fa-twitter alt"
          >
            <span className="label">Twitter</span>
          </a>
        </li> */}
          <li>
            <a href="https://www.facebook.com/108119151018294" className="icon fa-facebook alt">
              <span className="label">Facebook</span>
            </a>
          </li>
          {/* <li>
          <a href="https://codebushi.com" className="icon fa-instagram alt">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://github.com/codebushi/gatsby-starter-stellar"
            className="icon fa-github alt"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a href="https://codebushi.com" className="icon fa-dribbble alt">
            <span className="label">Dribbble</span>
          </a>
        </li> */}
        </ul>
      </section>
      <p className="copyright">
        &copy; Amangwe {new Date().getFullYear()}
      </p>
    </footer>
  );
}

export default Footer
