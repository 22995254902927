import React, { useEffect, useRef, useState } from 'react'
import 'semantic-ui-css/semantic.min.css';
import Helmet from 'react-helmet'
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import '../assets/scss/main.scss'

import Footer from './Footer'

const Layout = ({ className = '', children, title }) => {
  const timeoutIdRef = useRef(null);
  const [loading, setLoading] = useState('is-loading');

  useEffect(() => {
    timeoutIdRef.current = setTimeout(() => {
      setLoading('');
    }, 100);

    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return (
    <div className={`body ${loading} ${className ?? ''}`}>
      <div id="wrapper">
        <Helmet
          title={title}
          link={[
            { rel: 'apple-touch-icon', sizes: '57x57', href: '/apple-icon-57x57.png' },
            { rel: 'apple-touch-icon', sizes: '60x60', href: '/apple-icon-60x60.png' },
            { rel: 'apple-touch-icon', sizes: '72x72', href: '/apple-icon-72x72.png' },
            { rel: 'apple-touch-icon', sizes: '76x76', href: '/apple-icon-76x76.png' },
            { rel: 'apple-touch-icon', sizes: '114x114', href: '/apple-icon-114x114.png' },
            { rel: 'apple-touch-icon', sizes: '120x120', href: '/apple-icon-120x120.png' },
            { rel: 'apple-touch-icon', sizes: '144x144', href: '/apple-icon-144x144.png' },
            { rel: 'apple-touch-icon', sizes: '152x152', href: '/apple-icon-152x152.png' },
            { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-icon-180x180.png' },
            { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/android-icon-192x192.png' },
            { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
            { rel: 'icon', type: 'image/png', sizes: '96x96', href: '/favicon-96x96.png' },
            { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
            { rel: 'manifest', href: '/manifest.json' },
            { rel: 'css', type: 'text/css', href: './static/fonts.css' },
          ]}
          meta={[
            { name: `description`, content: 'Amangwe economic revolution', },
            { property: `og:title`, content: 'Amangwe', },
            { property: `og:description`, content: 'Amangwe economic revolution', },
            { property: `og:type`, content: `website`, },
            { name: `twitter:card`, content: `summary`, },
            { name: 'application-name', content: 'foongit', },
            { name: 'msapplication-TileColor', content: '#ffffff' },
            { name: 'msapplication-TileImage', content: '/ms-icon-144x144.png' },
            { name: 'theme-color', content: '#ffffff' },
          ]}
        />
        {children}
        <a className="back-to-top" href="#top"><Icon name="angle up" color="grey" /></a>
        <Footer />
      </div>
    </div>
  )
};

export default styled(Layout)`
  #wrapper {
    position: relative;

    .back-to-top {
      position: sticky;
      top: calc(100vh - 5rem);
      bottom: 0;
      background: rgba(247, 247, 247, 0.95);
      left: 100%;

      width: 2em;
      height: 2em;
      /* border-radius: 1em; */
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      > i.icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
      }
    }
  }
`;
